/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";

// loading container
.spinner {
  margin-top: 20px;
  width: 65px;
  height: 65px;
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner .path {
  stroke-dasharray: 187;
  stroke-dashoffset: 186;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  stroke: blue;
  -webkit-animation: dash 1.4s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 186;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 186;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// formio hide loader
formio > div > div:first-child {
    display: none !important;
}

.ngx-datatable.bootstrap .datatable-footer {
  background: unset;
  color: #000;
}

$primaryColor: #1C448C;

.mat-toolbar {
  background-color: $primaryColor!important;
  color: white;
}

button.mat-primary {
  background-color: $primaryColor!important;
}

ngx-datatable .datatable-row-odd,
ngx-datatable .datatable-row-even {
  background-color: white !important;
}
